<template>
  <div id="policyWft2" >
     <div id="warp">
      <div class="form-agreement">
        <div class="rules_area">
          <h2>客 户 协 议</h2>
          <strong>前言</strong>
          <ul>
            <li>伦敦金/银买卖涉及潜在的利益与损失，难以保证绝对的盈利或亏损。若市场情况不利，损失有可能超过最初投入的金额。因金银买卖的价位波动受多种全球性的因素影响，这些因素大多是难以预计的，故金银买卖的剧烈波动可能导致投资者被迫对即时亏损进行结算。本公司的职员虽然不断关注市况，但他们并不能对市况作出任何预测性的保证，也无法完全保证损失的限额。</li>
          </ul>
          <ul>
            <li>
              <h4> 本协议具有法律效力，请仔细审阅 </h4>
              <p class="indent"> 本法律合约乃由本公司(下称“交易商”）及其后继人或受让人，与本合约的相对方(下称“客户”)共同订立，用于调整客户通过交易商开设账号利用伦敦金/银场外OTC市场从事投机及/或购买、及/或卖出现货伦敦金/银(下称“OTCGOLD”)所产生的权利义务。客户确认已了解下述有关杠杆式OTCGOLD交易的资讯以及开设账号页面提供给客户的风险披露声明、隐私保护等相关档案。 </p>
              <p class="indent"> OTCGOLD交易仅适合专业机构或投资经验丰富的人士，要求其财政状况可承受远大于保证金或存款金价值的损失。 </p>
              <p class="indent"> OTCGOLD的业务并不需公开喊价。虽然许多场所交易都以电脑为基础的系统提供报价和实际价格，但这两价格可能会因为市场的流动性影响而有所偏差。电子交易设施由以电脑为基础的系统进行交易下单、执行、配对，它们易受到系统临时故障的影响。 </p>
              <p class="indent"> 如果客户在任何电子市场从事交易，客户需面临与该电子交易系统相关的风险，包括硬体或软体的故障。系统故障可能造成客户的订单难以按照其指示执行或根本不能执行。 </p>
              <p class="indent"> 客户收回某些损失的能力可能受限于系统提供者、银行及/或其他金融机构设定的责任限度，此限度可能存在差异。 </p>
            </li>
          </ul>
          <h2>风 险 披 露 声 明</h2>
          <p class="indent mleft"> 声明并不能披露关于伦敦金/银交易(伦敦金及伦敦银)的所有风险及其它重要事项。鉴于有关风险，客户在开始进行伦敦金/银交易之前，应该清楚了解相关交易性质和风险程度，并知悉伦敦金/银交易并不适合一般投资者。客户必须根据个人投资经验、目的、财政状况和风险承受能力等相关因素仔细考虑伦敦金/银交易是否适合客户本人。客户在开户及开始交易前应该咨询法律及其它独立的专业意见。客户必须清楚理解买卖伦敦金/银的条件与条款及其义务。 </p>
          <ul>
            <li>
              <h4> 伦敦金/银交易的风险 </h4>
              <p class="indent"> 保证金交易风险 </p>
              <p class="indent"> 伦敦金/银交易带有相当高的风险。此类交易属于杠杆式交易，意指透过杠杆比例，藉以让客户以极低的初始保证金来控制金额极高的交易合约。即使市场上出现微小变动也会对客户已经或将要存入的资金造成极大的影响。为了保持头寸，客户对于存入本公司的初始保证金及任何追加资金需承担可能损失的后果。如果市场变动对客户不利或者使得保证金水准提高，而客户未能及时追加保证金来维持头寸，将会导致在亏损的情况下被强制平仓，客户须对此造成的亏损负责。 </p>
            </li>
            <li>
              <h4> 降低风险的指令或策略 </h4>
              <p class="indent clsontredw"> 客户设置损失限制在特定金额的指令(例如“止损限价”指令)有可能并不见效或无法执行。如果订单是停损限价单，交易商无法保证订单能以限价执行或会执行。此外，一些使用头寸合并的策略，例如差价或同价对敲与单做“长仓”或“短仓”存在相同的风险。 </p>
            </li>
            <li>
              <h4> 其他伦敦金/银交易的额外风险 </h4>
              <ol>
                <li> 1. 交易设施 <p> 大部分电子交易的设施由以电脑为基础的系统来进行交易指令的传递、执行、匹配、登记和交易清算。与所有的设施和系统一样，易受临时故障的影响。故客户收回某些损失的能力可能受限于系统提供者、市场、清算所及/或公司设定的有限度责任。这些有限度责任可能各有不同。 </p>
                </li>
                <li> 2. 电子交易 <p> 有别于公开喊价买卖的交易运作，客户通过电子交易可能会遇到由于电脑的硬体、软体及互联网传输引起的失误，而导致客户的指令处于未被执行或完全不会执行的问题，客户必须了解有关风险并自行承担相应的责任。 </p>
                </li>
                <li> 3. 在其他司法管辖区的交易 <p> 在其他司法管辖区市场(包括正式连接到本地市场的市场)进行的交易可能使客户面临其他风险。在那些市场的相关规定下，投资者得到的保障可能不同或甚至会减低对投资者的保障。在开始交易前客户应该询问任何与交易有关的规定，并了解本身所在地及其他司法管辖区可得到的补偿。 </p>
                </li>
                <li> 4. 暂停或限制交易与定价的关系 <p> 市场状况以及/或某些市场的运作条例(例如由于停市造成的任何伦敦金/银暂停交易)有可能增加客户损失的风险，因为其可能导致完成交易或平仓等交易指令很困难或不可能实现。此外，相关资产与伦敦金/银的正常交易关系可能不存在而缺乏相关资产的参考价格，因此很难评估或确定“公平”价格。 </p>
                </li>
                <li> 5. 存放的资金 <p> 客户必须熟悉各种有关“为进行当地或外地交易”而存放的金钱与财物有哪些保障，特别是在交易商资不抵债或破产的时候。客户可收回现金与财物的程度，受制于特定法例或当地的规则。在有些司法辖区，当清债出现亏空时，特地标明客户所有的财物将与现金一起被按比例加以分配。 </p>
                </li>
                <li> 6. 交易佣金与其他收费 <p> 客户在开始交易之前，应该了解清楚需支付的所有佣金、费用及其它收费。这些收费将会影响客户潜在的盈利或增加客户的损失。客户明白向伦敦金/银账号投入的保证金等不作为存款用途，也无任何利息收益。 </p>
                </li>
              </ol>
            </li>
          </ul>
          <h2>贵 金 属 买 卖 条 款 及 条 件</h2>
          <p> 交易商同意客户开立和持有不同币种的交易账号，并可以通过或透过客户的交易商账号向客户提供买卖OTCGOLD的服务。 </p>
          <ul>
            <li class="word">
              <h4> 定义及释义 </h4>
              <p> 「客户」一词用于本协议仅包括以下几种情况： </p>
              <p class="mleft"> 如属个人性质指客户自然人； <br> 如属独资商号指该独资经营者； <br> 如属合伙经营商号，指代表该商号时的合伙事务执行人； <br> 如客户为公司指该公司； <br> 交易商仅认可与本条款所界定的「客户」所产生的交易具备本协议之合法性。 </p>
              <p> 「本公司」 </p>
              <p class="mleft"> 指本公司及其后继人和受让人； </p>
              <p> 「交易账号」 </p>
              <p class="mleft"> 指客户与交易商根据本协议开设作买卖合约之账号； </p>
              <p> 「登录密码」 </p>
              <p class="mleft"> 指公司提供给客户用作登录网上服务及发出交易指令之交易账号编码及密码； </p>
              <p> 「交易指令」 </p>
              <p class="mleft"> 指客户通过交易账号作出买卖合约的开仓或平仓指令； </p>
              <p> 「入金」 </p>
              <p class="mleft"> 一词指客户将保证金转入交易商指定的户口，当交易商收到客户保证金后，将该笔保证金存入他(或她)于交易商处开立的交易账号内，即为入金凭证； </p>
              <p> 「交易优惠」 </p>
              <p class="mleft"> 指正常交易状态下本公司有权对投资者的特定交易状态提供优惠，其形式包括但不限于“点差优惠”、“红包”、“赠金点”、“交易赠金”等。若本公司判定交易存在异常，可收回相应的交易优惠。本公司对此交易优惠保留最终的解释权； </p>
              <p> 「伦敦金/银」 </p>
              <p class="mleft"> 指交易商与客户可能达成的买卖交易之贵重及普通金属； </p>
              <p> 「伦敦金/银交易」 </p>
              <p class="mleft"> 指以按斤形式进行的合约单位的伦敦金/银买卖； </p>
              <p> 「交易保证金」 </p>
              <p class="mleft"> 指交易商要求各客户为使协议生效而须存入的一笔类似押金之存款，作为对所有交易的担保； </p>
              <p> 「追加保证金」 </p>
              <p class="mleft"> 指在必需保证金因市场价格的不利波动而出现亏损或基于交易商的要求需提高保证金门槛时，客户按交易商要求填补保证金。追加保证金额必须是百分之百补足至必需保证金的数额； </p>
              <p> 「工作日」 </p>
              <p class="mleft"> 星期六、日及公布的法定公众假期以外的其他日期； </p>
              <p> 「合约」 </p>
              <p class="mleft"> 指一份由双方以口述、书面、或电子方式签订的、有关依据此协议内条款进行伦敦金/银交易的合约； </p>
              <p> 「交易日」 </p>
              <p class="mleft"> 指环球任何市场的本地伦敦金、伦敦银的可买卖日期。 </p>
              <p> 「即市交易」 </p>
              <p class="mleft"> 指每天结算时间以后做新单，至次日结算时间之前已经平仓的买卖，冬令时延迟一个小时； </p>
              <p> 「过夜交易」 </p>
              <p class="mleft"> 指每天结算时间以后做新单，而于次日结算时间仍未有平仓的买卖，冬令时延迟一个小时。 </p>
            </li>
            <li>
              <h4> 账 户 </h4>
              <ol>
                <li>1. 客户必须于交易商开立和持有不同币种的交易账号，并向交易商存入足够交易保证金以作买卖伦敦金/银合约用途。</li>
                <li>2. 客户需根据交易商的要求，提供开设账号所需的所有资料及档案。客户提供的资料及档案必须完整，真实及准确。客户需承诺并同意若提供给交易商的资料及档案上有任何重大的改变，其负有持续通知交易商的义务。</li>
                <li>3. 客户需接受、遵守及遵从所有由交易商不时制定并知会的交易规则。</li>
                <li>4. 收款账户名称：香港红狮集团有限公司</li>
              </ol>
            </li>
            <li>
              <h4> 差 价 </h4>
              <p> 交易商有绝对的酌情决定权决定伦敦金/银合约买入与卖出的价格的差价。本着履行本协议的所有目的，客户同意及接受由公司开出及决定的伦敦金/银合约价值及差价为公平及合理的。 </p>
            </li>
            <li>
              <h4> 买 卖 指 令 </h4>
              <ol>
                <li> 1. 客户的买卖指令，以通过电子交易平台下达为主，自由取舍，自己负责，公平公正。</li>
                <li>2. 客户向交易商发出的所有指令，必须清楚明确，所有指令均须遵守本地或其他有关市场当时存在或生效的所有章程、规则、条例、习惯、惯例、裁定及诠释，同时亦须遵守当时适用的所有法律、规则及条例，否则需承担所有指令所引致的不利后果。</li>
                <li> 3. 客户同意并声明其本身充分了解伦敦金/银现货买卖所涉及的风险，并确知其向交易商发出的指令，可能会受当时某种特殊情况影响而无法执行，如因未能连接或使用本网站或任何其他与本网站连结的网站或网页而蒙受任何直接、特殊、间接、相应或连带的损失，应由客户自行承担。此等损失包括但不限于任何第三者的行为或疏忽及自然灾害所导致。 <p> 交易商收到： </p>
                  <ol class="notype">
                    <li>(a)客户指令；</li>
                    <li>(b)由其授权职员签署的对账单、电话录音等；</li>
                  </ol>
                  <p> 均可作为买卖指令的有效凭证。 </p>
                </li>
              </ol>
            </li>
            <li>
              <h4> 保证金及结算 </h4>
              <ol>
                <li>1. 根据相关《金融机构打击洗钱及恐怖分子资金筹集条例》（AMLO（Cap.615））的规定，客户仅能以本人的银行账户向交易商进行出、入金操作。存入保证金的金额由交易商不时指定，存入的货币须为交易商所接受的币种。若存入货币并非港元或美元，交易商将以其取得的汇率，将之兑换成港元或美元。</li>
                <li>2. 倘交易商本身酌情认为需要提高保证金，或者因为客户交易不顺利而需要追加保证金，客户必须无条件补足保证金，否则将接受交易商的处理或处罚。</li>
                <li>3. 凡客户交予交易商的任何款项，不论名义上作为存款、保证金、抵押或作任何其他用途，交易商概不付予客户任何利息。</li>
                <li> 4. 对于利用网路报价延迟等不正当手段进行交易的，经交易商核实后将扣除该交易点差优惠，以及保留扣除该交易单盈亏的权力。 </li>
                <li>5.客户申请取款，需要登入网站上的“用户中心”办理，申请取款时间为工作日9：00至18:00。客户的取款必须符合相关《金融机构打击洗钱及恐怖分子资金筹集条例》（AMLO（Cap.615））的规定，交易商有义务尽最大可能使客户取款过程合规，由此产生的不便，敬请理解。</li>
              </ol>
            </li>
            <li>
              <h4> 利 息 </h4>
              <p> 客户未平仓的合约或客户账号所须缴付的利息，将按交易商所规定的方式计算。利息的变动由交易商决定，恕不另行通知。 </p>
            </li>
            <li>
              <h4> 录 音 </h4>
              <p> 客户同意交易商有权采用录音程式，以作为所录取指示或通讯之确实证据。客户进一步同意在涉及任何客户或交易商的纠纷或诉讼中，任何一方可以使用此类录音或眷本作证据。客户理解并同意交易商定期根据其确立的程式整理这类录音。 </p>
            </li>
            <li>
              <h4> 汇率变动风险 </h4>
              <p class="clsfontbg"> 交易账号必须以美元或交易商所同意的其他币种为单位(如人民币)，倘若客户以美元之外(人民币除外)的其他币种存取款： </p>
              <ol>
                <li>1. 客户必须独自承担因汇率价格变动而导致之任何收益或损失。</li>
                <li>2. 客户同意交易商按其全权决定之形式及时间对货币作出兑换，以实行其根据本协定而采取之任何行动或步骤。</li>
              </ol>
            </li>
            <li>
              <h4> 客户声明与保证 </h4>
              <p> 客户声明并保证其财务状况良好。客户在开始进行伦敦金/银交易之前已非常仔细地考虑了伦敦金/银交易的风险并明白客户现有资产中可为风险资本金的部分。客户保证及声明即使在伦敦金/银交易中出现损失也不会对其生活方式带来任何改变： </p>
              <ol class="list_01">
                <li>1、到达法定年龄，具有法律能力。</li>
                <li>2、只有账号持有人/ 客户本人享有其账号之利益。</li>
                <li>3、不论此后任何相反的裁决，除了1所述，视同客户有足够投资经验、承受风险及财政上的能力以进行OTCGOLD交易。</li>
                <li>4、客户目前不受雇于任何交易所、任何交易所持有绝大部分资本的公司、任何交易所的成员及或任何在交易所注册的公司、任何银行、信托机构或保险公司，若客户受雇于上述公司，客户必须立即以书面形式通知交易商。</li>
                <li>5、客户提供的资讯皆为真实、准确和完整。若任何讯息变化，客户有责任迅速通知交易商。</li>
                <li>6、完全遵守当地有关法例，包括为遵守该等地区或司法管辖区内须遵守之任何其他手续以取得政府或其他方面之同意，以及因为使用本平台进行交易而需要支付当地任何相关税项、关税及其他金额。客户在本平台进行交易，将被视为该客户向交易商声明及保证已遵守当地法律及规定。倘若客户对情况有疑问，客户必须寻找专业之法律意见。</li>
              </ol>
            </li>
            <li>
              <h4> 交易商的责任 </h4>
              <p> 交易商不对因传输或通讯设施故障、电力中断、数据传送设施故障、未经授权擅自接连、盗窃、火灾、战争、罢工、民众骚乱、恐怖主义行为或威胁、天灾或劳资纠纷所引起客户的损害负责。 </p>
              <p> 交易商仅对自身的蓄意过错或欺诈造成的客户损害负责。电力短路或任何其他交易商所不能控制或预计的原因带来的指令传输的延迟，或是因其他参与人的过失所引起的损失，交易商不负有责任。 </p>
              <p>客户同意若其因使用该项服务而令自己的电脑、软体、数据机、电话或其他财物受损、交易商一概不须负责。</p>
            </li>
            <li>
              <h4> 推广商披露 </h4>
              <p> 交易商和推广商完全独立，交易商没有监管其推广商的权利与义务，也不对推广商作出的声明负责。交易商和推广商直接的协定并不建立合伙或合资机构关系，因此推广商不是交易商的代表或职员，并无权代表交易商进行交涉或采取任何其他行为： </p>
              <ol>
                <li>1、 客户理解并同意如果客户在交易商开设交易账号是经由推广商介绍而来，交易商有义务向该推广商提供客户账号交易活动有关的资料；如果客户是通过推广商开设账户并且其交易过程涉及交易商，则客户承诺其交易受本协议的约束。</li>
                <li>2、 客户理解并确认交易商可能对推广商给予报酬，此类报酬可能按照每笔交易或其他方式给予。</li>
                <li>3、 客户理解非经交易商处理的客户资金，只要不在交易商的管理、保管或控制范围，交易商均不承担任何责任。</li>
                <li>4、 客户理解推广商或其他出售/ 提供交易系统，课程、程式、研究或建议的第三者与交易商没有关联，且此等服务不受交易商、政府机构的监管。交易商不对客户使用该资讯或建议带来的损失负责。</li>
                <li>5、 客户在此确认，同意和理解伦敦金/银交易所带来的一切风险，同时明白通过交易商、任何第三者的交易系统、课程、程式、包括推广商提供的研究或建议进行的交易并不一定带来盈利、避免风险或限制风险。</li>
                <li>6、 客户确认交易商与其推广商未就客户未来的盈亏作出任何许诺。客户明白伦敦金/银交易带有相当高的风险，客户必须细阅上述的《风险披露声明》。</li>
                <li>7、 对于客户已经或将会从推广商或任何第三方处获得的资讯或建议，交易商不能控制，也无法担保其资讯及建议的准确性或完备性。</li>
              </ol>
              <p>若本协议中英文版本不一致，应以中文版为准。</p>
            </li>
            <li>
              <h4> 高风险投资 </h4>
              <p> 客户应注意以保证金为基础的伦敦金/银交易是金融市场上最高风险的投资工具之一，且仅适合于有丰富经验的专业投资者和机构。在交易商开立的交易账号允许客户以很高的杠杆比例(可高达客户账号资产的100 倍，杠杆比例可由交易商不时或按照其他适用的规例进行调整)进行伦敦金/银交易。客户在开户或进行伦敦金/银交易之前应仔细地考虑其可承受之风险，并明白用于伦敦金/银交易进行投机的资金应属于风险资本金。客户保证及声明即使在伦敦金/银交易中出现严重亏损也不会对其现在/未来的生活方式及财政状况带来任何影响。若客户未曾投资于高风险的投资工具，客户需要在正式买卖之前了解和学习伦敦金/银交易相关知识，并咨询有关专业意见。 </p>
            </li>
            <li>
              <h4> 协议修改 </h4>
              <p> 客户同意交易商可不时酌情决定修订、取消、或更替本协议之任何条款或增补任何新条款，交易商会把这些修改或变更在本公司网站公布来通知客户。客户须定时浏览有关条款的变更并同意受此约束。 </p>
            </li>
            <li>
              <h4> 管辖的法例及机构 </h4>
              <p> 本协议之有效性，解释，释义及执行方面将受相关法例所规定，客户及公司双方并同意适用相关法律调整本协议所产生的权利义务。 </p>
              <p> 双方约定，在发生争议诉诸法院之前，优先选择采用仲裁的方式对争议进行裁决。仲裁机构指定为相关国际仲裁中心，仲裁庭的组成形式届时由交易商选定，仲裁以英文的形式进行。 </p>
            </li>
          </ul>
          <h2>免 责 声 明</h2>
          <ol class="list_01">
            <li>
              <h4> 1、 互联网故障 </h4>
              <p> 由于互联网的运作不在交易商控制范围之内，信号经由互联网和路由传送的稳定性需要依赖客户设备或连接的可靠性，故交易商不对互联网上交易中出现的通讯故障、错误或延迟负责。 </p>
            </li>
            <li>
              <h4> 2、 市场风险和网上交易 </h4>
              <p> 伦敦金/银交易涉及相当大的风险，其并非对每个投资者都适合。请参照风险披露声明和本协议，了解有关风险的详细资料。虽然网上交易为客户带来很多方便或使得交易更有效率，但它并不降低伦敦金/银交易本身的风险。 </p>
            </li>
            <li>
              <h4> 3、 密码保护 </h4>
              <p> 客户必须将密码保密。客户同意对所有经电邮或电子交易平台、电话、书面传送给交易商的指示负责，这些指示只要符合客户密码或客户签名和账号号码认证，交易商即可善意相信这是客户的意思表示。交易商并没有责任对该意思表示作实质性的查询，也没有责任因为依据该意思表示所采取的或不采取的行动所造成的后果负责。客户须对密码的保密性、安全性及其使用独自承担责任。 </p>
            </li>
            <li>
              <h4> 4、 报价错误 </h4>
              <p> 倘若报价或成交价出现错误，交易商对于账号结算的相应错误并不负责。有关错误包括但不限于：交易员的错误报价、非国际市场价之报价、或是任何报价错误(例如由于硬体、软体或者通讯线路或系统故障导致报价错误或者第三者提供了错误的外部资料)。交易商不需为错误所导致的账号损失负责，客户下单时需预留足够的时间以供系统计算所需保证金及执行订单。若订单的执行价格或订单设定价格和市场价格过于接近，这可能会触发其他订单(所有订单类型)发出保证金提示。交易商不会对由于系统没有足够时间执行订单或进行运算所产生的保证金提示、账号结余或账号头寸负责。上文不得视作内容尽列，一旦发生报价或执行错误，交易商保留对有关账号进行必要更正或调整的权利，通过此种绝对的酌情权，交易商可判定客户利用报价错误而成立的订单无效，相关盈亏自始不产生效力。若因此带来任何损失、损害或责任，客户同意予以赔偿使交易商不受损害。 </p>
            </li>
            <li>
              <h4> 5、 操控价格、执行及平台 </h4>
              <p> 交易商严禁客户以任何形式对价格、执行及平台进行操控，包括严禁多个主体使用一个账号、单个主体使用多个账号等。此种操控可能导致交易未成立或可被撤销的法律后果。若交易商怀疑任何交易账号有从事操控伦敦金/银合约价格的嫌疑，交易商保留对账号进行调查及审核、并从涉嫌账号中扣除由相关操作所赚取盈利款项的权利。对于涉嫌从事操控的账号，交易商依据绝对酌情权，要求交易员进行干预、对下单进行核准以及终止有关客户的账号。对于由违规性套戥及或操控所产生的任何纠纷，交易商将依据绝对酌情权进行解决。交易商可依据酌情权决定向任何相关监管机构或执法机构报告有关事件。于此处所陈述的任何行动或决议并不影响交易商对客户和其雇员拥有的任何权利。 </p>
            </li>
            <li>
              <h4> 6、套戥 </h4>
              <p> 互联网、连线延误、报价上的误差或报价系统的漏洞有时会造成在本公司交易平台上的报价出现错误。因报价错误而透过差价获利的行为，并不能存在于客户直接向本公司平台进行买卖的场外交易市场中。本公司不容许客户在本公司的交易平台上进行此等违规的套戥活动。本公司保留权利对涉及上述交易的账户进行必要的修正和调整。本公司可依据绝对酌情权，要求交易员进行干预或核准所有下单以及终止有关客户的账户，而不需事先通知客户。对违规的“套戥”或操控价格而产生的纠纷，本公司将依据绝对酌情权进行解决。本公司保留冻结客户提款的权利直至能够完全解决上述的问题为止。 </p>
            </li>
          </ol>
          <h2>私 隐 条 款</h2>
          <p> 交易商根据《个人资料(私隐)条例》来保护客户的个人资料私隐。在交易商使用客户资料以提供其最佳之服务时，亦会高度关注对于客户资料使用之安排。交易商会根据相关法例《个人资料(私隐)条例》之规定，力求完全符合个人资料保证要求。为此，交易商会确保其雇员遵守法例规定的保安和保密严谨标准。 </p>
          <ol class="list_01">
            <li>1、 交易商收集的各项个人资料，只供业务运作及其他有关活动之用；</li>
            <li>2、 交易商致力于使用其他资料保障工具确保网站安全及符合业界标准，例如：防火墙、认证系统(密码和个人身份证号码等)和操控机制来控制未获授权的系统进入和存取资料；</li>
            <li>3、 交易商会将客户个人资料保密储存，防止其个人资料被盗用，为达到私隐保护之最佳效果，除符合本款第五、第七条之规定外，客户充分理解交易商不会向客户本人以外的任何主体提供客户的资料、信息，即使该主体声称其为获授权方，交易商亦无审核其授权合法性之能力与义务；</li>
            <li>4、客户可不时查阅及更改由交易商所保存的有关个人资料；</li>
            <li>5、客户同意交易商可根据任何法例或监管机构之规定，向监管当局及其他政府机构披露客户所有资料；</li>
            <li>6、交易商可能会在客户的电脑设定及存取交易商cookies，以协助交易商分析并了解有关之市场推广策略的效果。交易商及其雇员可能会于交易商的产品和服务中使用cookies来分析客户在交易商网站的浏览行为，对于收集得来和共用的资料是不具姓名及无法被个别识别的；</li>
            <li>7、客户同意交易商可能授权第三方使用客户个人资料，这些包括以合约形式向交易商提供服务的公司。这些公司必须对客户的个人资料保密。</li>
          </ol>
          <p> 交易商将尽一切所能确保其收集所得的客户个人资料被妥善地保存在储存系统，并只有获授权的雇员或有限的向交易商提供服务的公司可查阅有关资料，但鉴于互联网之公开及全球性质，交易商可能无法保护及保密客户个人资料不受第三者的恶意干扰。凡经由交易商网页流览他人运作的网页，客户的个人资料均不属交易商的个人资料私隐条款保障范围内。 </p>
        </div>
      </div>
    </div>
  
  

  </div>
</template>

<script>
import eventBusMitter from '../../../utils/eventbus';
export default {
  name: 'policyWft2',
  data(){
    return{
      
    }
  },
  created(){
    // 接收eventBus传来的值
    // eventBusMitter.on('eventBus',(res)=>{
    //   this.control = res.key;
    // })
     eventBusMitter.emit('eventBus', {key:true})
  },
  methods:{

  }
}
</script>

<style lang="less" scope>
      body {
        background-color: #fff;
        color: #515151;
      }

      .rules_area {
        padding: 0 0.4rem;
        text-align: justify;
        font-size: 12px;
        line-height: 20px;
      }

      .rules_area h2:first-child {
        color: #303030;
        font-size: 24px;
        text-align: center;
        line-height: 24px;
        padding-top: 1.28rem;
        padding-bottom: 0.72rem;
      }

      .form-agreement {
        padding-bottom: 1.8rem;
      }

      h2 {
        padding: 0.3rem 0;
      }

      ol {
        padding-left: 0.6rem;
        list-style: none;
      }

      ul ol {
        list-style: none;
        padding-left: 0.6rem;
      }

      .top-pad {
        background-color: #e8e8e8;
        padding-bottom: 0.4rem;
      }

      .rules_area ul li p.indent {
        text-indent: 1rem;
      }

      .rules_area ul li h4 {
        padding-top: 4%;
      }

      .app-xinsheng-view .top-pad {
        display: none;
      }

      .app-xinsheng-view #go-back-top {
        display: none !important;
      }

</style>
